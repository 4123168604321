import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import queryString from "query-string";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppLayout from "./layout";
import Copyright from "../components/copyright";
import { useHistory } from "react-router-dom";
import ReactCodeInput from "react-code-input";
import Container from "../components/container";
import AuthService from "../services/auth-service";
import BackButton from "../components/backButton";
import logo from "../allSet.svg";
import useStylesAllSet from "../css/allSet";
import ErrorMessage from "../components/errorMessage";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import BuyByeService from "../services/buybye-service";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dial = ({ ...props }) => {
    console.log(props);
    if (props.paymentSystem === "epg") {
        return (
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {Languages[props.locale].payments.modalTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        dangerouslySetInnerHTML={{
                            __html:
                                Languages[props.locale].payments[props.paymentSystem].body,
                        }}
                    ></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        {Languages[props.locale].debt.cancel}
                    </Button>
                    <form action={props.PaymentPage} method="post">
                        <input
                            type="Hidden"
                            name="TransactionID"
                            readOnly={true}
                            value={props.TransactionID || ""}
                        />
                        <Button type="submit" value="Submit">
                            {Languages[props.locale].debt.continue}
                        </Button>
                    </form>
                </DialogActions>
            </Dialog>
        );
    } else if (props.paymentSystem === "stripe") {
        return (
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {Languages[props.locale].payments.modalTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        dangerouslySetInnerHTML={{
                            __html:
                                Languages[props.locale].payments[props.paymentSystem].body,
                        }}
                    ></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        {Languages[props.locale].debt.cancel}
                    </Button>
                    <Button onClick={props.goRegister} value="Submit">
                        {Languages[props.locale].debt.continue}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return <></>;
    }
};

const useStyles = makeStyles((theme) => ({
    cardNumber: {
        marginBottom: "0px",
    },
    creditCard: {
        borderBottom: "1px solid #eee",
        display: "flex",
        paddingBottom: "10px",
        justifyContent: "space-around",
    },
    icon: {
        display: "inline-block",
        marginRight: "1em",
        width: "45px",
        height: "45px",
        marginBlockStart: "1em",
    },
    trashIcon: {
        marginBlockStart: "1em",
        color: theme.palette.red,
        fontSize: "2em",
        cursor: "pointer",
    },
    creditcards: {
        paddingBottom: "11%",
    },
    paymentHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    floatContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "20%",
    },
}));

export default function AllSet() {
    const classes = useStylesAllSet();


    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const { locale } = React.useContext(LocaleContext);
    const [PaymentPage, setPaymentPage] = React.useState(undefined);
    const [TransactionID, setTransactionID] = React.useState(undefined);
    const [PaymentSystem] = React.useState(BuyByeService.getPaymentSystem());

    const handleClickOpen = () => {
        console.time();
        async function main() {
            if (PaymentSystem === "epg") {
                let _cards;
                console.log("payments");
                _cards = await BuyByeService.createCustomerCard({
                    step: 1,
                    ReturnPath: `https://utils.reckon.ai/bypass?url=${process.env.BYPASS || `${process.env.PUBLIC_URL}/closeDoor?step=2`
                        }`,
                });
                console.log(_cards);
                if (_cards.success) {
                    BuyByeService.setToken("lastTransactionID", {
                        TransactionID: `${_cards.result.TransactionID}`,
                    });
                    setPaymentPage(_cards.result.PaymentPage);
                    setTransactionID(`${_cards.result.TransactionID}`);
                    setOpen(true);
                }
                console.timeEnd();
            } else if (PaymentSystem === "stripe") {
                setOpen(true);
            } else {
                setOpen(true);
            }
        }
        main();
    };

    const handleClose = () => {
        setOpen(false);
    };
    const goRegister = () => {
        /* history.push("/card/form?location=allSet"); */
        history.push({
            pathname: "/card/form",
            query: { location: "allSet" }
        })
    };

    const onClick = () => {
        //history.push("/card/form");
        handleClickOpen();
    };

    return (
        <AppLayout>
            <Container component="main">
                <CssBaseline />
                <div className={classes.paper}>
                    <div style={{ alignItems: "center", display: "flex", flexDirection: "column", }}>
                        <img src={logo} style={{ height: "35%", marginTop: "15vh" }} alt="avatar" />
                        <h1 className="header__title" style={{ color: "rgb(69 101 20)", marginTop: "13vh", paddingRight: "0px" }}>{Languages[locale].profileSet.profileSet}</h1>
                        <text
                            variant="body2"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                color: "#141A30",
                                marginTop: "20px",
                                fontFamily: 'Roboto',
                                fontStyle: "normal",
                                fontSize: "14px",
                                textAlign: "center"
                            }}
                        >
                            {Languages[locale].profileSet.text}
                        </text>
                    </div>
                    <div className={classes.bottom}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{
                                borderRadius: "50px",
                                padding: "12px 12px",
                            }}
                            onClick={onClick}
                        >
                            {Languages[locale].profileSet.addCreditCardButton}
                        </Button>
                        <Link
                            href="/openBuyBye"
                            variant="body2"
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            <span style={{}}>{Languages[locale].profileSet.skip}</span>
                        </Link>

                        <div className={classes.floatContainer}>

                            <Dial
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                handleClose={handleClose}
                                goRegister={goRegister}
                                PaymentPage={PaymentPage}
                                TransactionID={TransactionID}
                                paymentSystem={PaymentSystem}
                                locale={locale}
                            ></Dial>
                        </div>
                    </div>
                </div>
            </Container>
        </AppLayout>
    );
}
