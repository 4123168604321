import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import Copyright from "../components/copyright";
import { useHistory } from "react-router-dom";
import BackButton from "../components/backButton";
import logo from "../FoodSVG.svg";
import AuthService from "../services/auth-service";
import useStyles from "../css/signup";
import ErrorMessage from "../components/errorMessage";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = React.useState(undefined);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { locale } = React.useContext(LocaleContext);
  const handleOnChange = (value) => {
    setPhone(value);
  };
  const handleChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault({
      username: name,
      phone,
      password,
      email,
      role: "user",
      locale,
    });
    function emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
    if (emailIsValid(email)) {
      let _phone;
      console.log();
      if (!phone.includes('+')) {
        _phone = `+351${phone}`
      } else _phone = phone
      const res = await AuthService.register({
        username: name,
        phone: _phone,
        password,
        email,
        role: "user",
      });
      if (res.success) {
        history.push("/verify");
      } else {
        setError(res.errorMessage.message);
      }
    } else {
      setError(Languages[locale].error.validData);
    }
  };

  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <BackButton history={history} location={"/signin/"} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} style={{ width: "15em" }} alt="avatar" />
          <ErrorMessage
            errorMessage={{ message: error }}
            height={"1.5em"}
          ></ErrorMessage>
          <form className={classes.form} noValidate onSubmit={handleRegister}>
            <input
              className={"nxt input-round"}
              placeholder={Languages[locale].global.name}
              value={name}
              onChange={handleChange("name")}
              type="text"
            />
            <input
              className={"nxt input-round"}
              placeholder={Languages[locale].global.email}
              value={email}
              onChange={handleChange("email")}
              type="text"
            />

            <input
              className={"nxt input-round"}
              placeholder={Languages[locale].global.phone}
              value={phone}
              onChange={handleChange("phone")}
              type="text"
            />

            <input
              className={"nxt input-round"}
              placeholder={Languages[locale].global.password}
              value={password}
              onChange={handleChange("password")}
              type="password"
            />
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "16.37px",
                textAlign: "center",
              }}
            >
              {Languages[locale].global.form}
              <p style={{ margin: "2px", fontWeight: 700 }}>
                {Languages[locale].global.terms} & {Languages[locale].global.privacyPolicy}
              </p>
            </p>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{
                borderRadius: "50px",
                padding: "12px 12px",
              }}
            >
              {Languages[locale].global.signUpButton}
            </Button>

            <Link
              href="/signin"
              variant="body2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {Languages[locale].global.signInTip}
            </Link>
          </form>
        </div>

        <p
          style={{
            textAlign: "center",
            lineHeight: "14px",
            fontSize: "10px",
            fontWeight: "normal",
            fontStyle: "normal",
            marginTop: "10vh"
          }}
        >
          {"Copyright © "}
          <Link
            color="inherit"
            href="/reckon.ai"
          >
            {process.env.main || "Reckon.ai"}
          </Link>{" "}
          {new Date().getFullYear()}
          {""}
        </p>

      </Container>
    </AppLayout>
  );
}
