import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import queryString from "query-string";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppLayout from "./layout";
import Copyright from "../components/copyright";
import { useHistory } from "react-router-dom";
import Container from "../components/container";
import logo from "../allSet.svg";
import useStylesAllSet from "../css/allSet";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import Button from "@material-ui/core/Button";


export default function AllSet() {
    const classes = useStylesAllSet();

    const history = useHistory();
    const { locale } = React.useContext(LocaleContext);

    return (
        <AppLayout>
            <Container component="main">
                <CssBaseline />
                <div className={classes.paper}>
                    <div style={{ alignItems: "center", display: "flex", flexDirection: "column", }}>
                        <img src={logo} style={{ height: "35%", marginTop: "15vh" }} alt="avatar" />
                        <h1 className="header__title" style={{ color: "rgb(69 101 20)", marginTop: "13vh", paddingRight: "0px" }}>{Languages[locale].paymentSet.paymentSet}</h1>
                        <text
                            variant="body2"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                color: "#141A30",
                                marginTop: "20px",
                                fontFamily: 'Roboto',
                                fontStyle: "normal",
                                fontSize: "14px",
                                textAlign: "center"
                            }}
                        >
                           {Languages[locale].paymentSet.text}
                        </text>
                    </div>
                    <div className={classes.bottom}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{
                                borderRadius: "50px",
                                padding: "12px 12px",
                            }}
                            onClick={() => history.push("openBuyBye")}
                        >
                            {Languages[locale].paymentSet.openButton}
                        </Button>
                    </div>
                </div>
            </Container>
        </AppLayout>
    );
}
