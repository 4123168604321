import React from "react";
import BackButton from "../components/backButton";
import AppLayout from "./layout";
import BuyByeService from "../services/buybye-service";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../components/errorMessage";
import Languages from "../languages/index";
import LocaleContext from "../context/LocaleContext";

const random = Math.floor(Math.random() * 25 + 1);

const formatCardNumber = (cardNumber) =>
  cardNumber.replace(/\s/g, "").replace(/(\d{4})/g, "$1 ");

const YearOptions = () => {
  const year = new Date().getFullYear();
  const years = [
    year + 6,
    year + 5,
    year + 4,
    year + 3,
    year + 2,
    year + 1,
    year,
  ];
  const options = years.map((year) => {
    return <option key={year}>{year}</option>;
  });

  return [<option key="yy">Year</option>, ...options];
};

const MonthOptions = () => {
  const months = [...Array(12).keys()];
  const options = months.map((month) => {
    return <option key={month}>{month + 1}</option>;
  });
  return [<option key="mm">Month</option>, ...options];
};
const CreditCard = () => {
  const history = useHistory();
  const [cvv, setCVV] = React.useState("");
  const [error, setError] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("#### ##### #### ####");
  const [mm, setMM] = React.useState("MM");
  const [yy, setYY] = React.useState("YY");
  const { locale } = React.useContext(LocaleContext);
  const [cardHolder, setCardHolder] = React.useState(Languages[locale].creditCardForm.fullName);
  const locationAllSet = history.location.query?.location;
  console.log(locationAllSet);

  const onFocus = (e) => {
    let front = document.getElementsByClassName("card-item")[0];
    front.classList.add("-active");
  };

  const onBlur = () => {
    let front = document.getElementsByClassName("card-item")[0];
    front.classList.remove("-active");
  };

  const onChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "cvv":
        setCVV(event.target.value);
        break;
      case "cardHolder":
        setCardHolder(event.target.value);
        break;
      case "cardNumber":
        console.log(event.which);
        console.log(event);
        if (
          event.target.value.length > 4 &&
          event.target.value.length % 5 == 0
        ) {
          setCardNumber(formatCardNumber(event.target.value));
        } else {
          setCardNumber(event.target.value);
        }

        break;
      case "mm":
        setMM(event.target.value);
        break;
      case "yy":
        setYY(event.target.value);
        break;
      default:
        break;
    }
  };

  const btnClick = async (e) => {
    e.preventDefault();
    let ccNum = cardNumber.replaceAll(" ", "");
    console.log(ccNum);
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;

    if (visaRegEx.test(ccNum)) {
      console.log("Im visa");
      isValid = true;
    } else if (mastercardRegEx.test(ccNum)) {
      console.log("Im master");
      isValid = true;
    } else if (amexpRegEx.test(ccNum)) {
      console.log("Im american");
      isValid = true;
    } else if (discovRegEx.test(ccNum)) {
      console.log("Im discovery");
      isValid = true;
    }
    if (isValid) {
      let res = await BuyByeService.createCustomerCard({
        number: ccNum,
        cvc: cvv,
        exp_month: `${mm}`,
        exp_year: `${yy}`,
      });
      if (res.success) {
        history.push(locationAllSet ? "/paymentSet" : "/profile?value=1");
      } else {
        setError(res.errorMessage.message);
      }
    } else {
      setError("Insert a valid card.");
    }
  };
  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <BackButton
        history={history}
        location={locationAllSet ? `/allSet` : "/profile?value=1"}
        style={{ background: "white" }}
      ></BackButton>

      <div className="card-form">
        <div className="card-list">
          <div className="card-item">
            <div className="card-item__side -front">
              <div className="card-item__focus" />
              <div className="card-item__cover">
                <img
                  src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${random}.jpeg`}
                  className="card-item__bg"
                  alt="card-item__bg"
                />
              </div>
              <div className="card-item__wrapper">
                <div className="card-item__top">
                  <img
                    src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                    className="card-item__chip"
                    alt="aa"
                  />
                  <div className="card-item__type">
                    <div name="slide-fade-up"></div>
                  </div>
                </div>
                <label htmlFor="cardNumber" className="card-item__number">
                  {cardNumber}
                </label>
                <div className="card-item__content">
                  <label htmlFor="cardName" className="card-item__info">
                    <div className="card-item__holder">{Languages[locale].creditCardForm.cardHolder}</div>
                    <div name="slide-fade-up">
                      <div className="card-item__name">{cardHolder}</div>
                    </div>
                  </label>
                  <div className="card-item__date">
                    <label htmlFor="cardMonth" className="card-item__dateTitle">
                      {Languages[locale].creditCardForm.expires}
                    </label>
                    <label htmlFor="cardMonth" className="card-item__dateItem">
                      <div name="slide-fade-up">
                        <span>{mm}</span>
                      </div>
                    </label>
                    /
                    <label htmlFor="cardYear" className="card-item__dateItem">
                      <div name="slide-fade-up">
                        <span>{yy}</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-item__side -back">
              <div className="card-item__cover">
                <img
                  src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${random}.jpeg`}
                  className="card-item__bg"
                  alt="cu"
                />
              </div>
              <div className="card-item__band" />
              <div className="card-item__cvv">
                <div className="card-item__cvvTitle">CVV</div>
                <div className="card-item__cvvBand">
                  <span>*{cvv}</span>
                </div>
                <div className="card-item__type"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-form__inner">
          <ErrorMessage errorMessage={{ message: error }}></ErrorMessage>
          <div className="card-input" >
           {/*  <label htmlFor="cardNumber" className="card-input__label">
              Card Number
            </label> */}
            <input
              type="text"
              onChange={onChange("cardNumber")}
              value={cardNumber !== "#### ##### #### ####" ? cardNumber : ""}
              placeholder={Languages[locale].creditCardForm.cardNumber}
              className="card-input__input"
              autoComplete="off"
              maxLength="19"
              pattern="\d\d\d\d \d\d\d\d \d\d\d\d \d\d\d\d"
            />
          </div>
          <div className="card-input">
            {/* <label htmlFor="cardName" className="card-input__label">
              Card Holder
            </label> */}
            <input
              type="text"
              id="cardName"
              placeholder={Languages[locale].creditCardForm.cardHolder}
              onChange={onChange("cardHolder")}
              value={cardHolder !== "FULL NAME" && cardHolder !== "NOME COMPLETO" ? cardHolder : ""}
              className="card-input__input"
              autoComplete="off"
            />
          </div>
          <div className="card-form__row">
            <div className="card-form__col">
              <div className="card-form__group">
               {/*  <label htmlFor="cardMonth" className="card-input__label">
                  Expiration Date
                </label> */}
                <select
                  onChange={onChange("mm")}
                  placeholder={Languages[locale].creditCardForm.month}
                  className="card-input__input -select"
                  id="cardMonth"
                  defaultValue={Languages[locale].creditCardForm.month}
                >
                  <MonthOptions></MonthOptions>
                </select>
                <select
                  onChange={onChange("yy")}
                  className="card-input__input -select"
                  id="cardYear"
                  defaultValue="Year"
                >
                  <YearOptions></YearOptions>
                </select>
              </div>
            </div>
            <div className="card-form__col -cvv">
              <div className="card-input">
                {/* <label htmlFor="cardCvv" className="card-input__label">
                  CVV
                </label> */}
                <input
                  type="text"
                  className="card-input__input"
                  id="cardCvv"
                  value={cvv}
                  placeholder="CVV"
                  onChange={onChange("cvv")}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  maxLength={3}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <button className="card-form__button" onClick={btnClick}>
          {Languages[locale].creditCardForm.submitButton}
          </button>
        </div>
      </div>
    </AppLayout>
  );
};
export default CreditCard;
